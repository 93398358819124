@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;

}

/* Contenedor principal del encabezado */
header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #f8f9fa; /* Ajusta el color de fondo según sea necesario */
}

/* Contenedor del logo y del menú */
.container {
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px; /* Ajusta el ancho máximo según sea necesario */
}

.history-section {
    border-radius: 20px;
    background-color: white;
    padding: 20px;
    margin-top: -120px;
    z-index: 0;
    position: relative;
}

.history-title {
    font-family: "Poppins", sans-serif;
    text-align: center;
    margin: 0;
    padding: 40px 20px;
    background-color: white;
    border-radius: 10px;
    z-index: 1;
}

.history-subtitle {
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: center; /* Centra el texto horizontalmente */
    align-items: center; /* Centra el texto verticalmente */
    margin: 10px;
    height: auto; /* Asegura que el contenedor ajuste su altura al contenido */
    text-align: center; /* Opcional, asegura que el texto esté centrado dentro de su contenedor si hay varias líneas */
    
}



hr{
    margin-bottom: 8vh !important;
    margin-top: 8vh !important;
}

.contenedor-main{
    width: 100%;
    height: 100%;
    /* Add your background pattern here */
    background-color: #ffffff;
    background-image: radial-gradient(rgba(12, 12, 12, 0.171) 2px, transparent 0);
    background-size: 30px 30px;
    background-position: -5px -5px;
}

  
.section-title{
    text-align: center;
    margin: 0;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    z-index: 1;
}