/* Estilo para el carrusel de fondo */
.carousel-item {
    height: 60vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
}

.carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
}
