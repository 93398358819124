/* Footer base styles */
footer {
    background-color: rgb(43, 43, 43);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Alinea verticalmente todos los elementos hijos */
    padding: 40px;
    flex-wrap: wrap; /* Permite que los elementos se envuelvan */
  }
  
  /* Container dentro del footer */
  footer .container {
    display: flex;
    flex-direction: row; /* Mantiene el diseño en fila para pantallas grandes */
    justify-content: space-between;
    width: 100%;
  }
  
  /* Alineación de columnas del footer */
  .footer-left,
  .footer-middle,
  .footer-right {
    flex: 1;
    padding: 10px;
  }
  
  .footer-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .footer-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  
  .footer-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
  }
  
  /* Estilos para los enlaces */
  footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  footer a {
    text-decoration: none;
    color: #fff;
  }
  
  footer a:hover {
    text-decoration: underline; /* Opcional: Agrega subrayado en hover */
  }
  
  /* Estilos para los iconos sociales */
  .social-icons {
    display: flex;
    flex-direction: row; /* Alinea íconos en una fila */
    align-items: center; /* Centra verticalmente los íconos en la fila */
    justify-content: center; /* Centra horizontalmente los íconos */
    gap: 20px; /* Espacio entre los íconos */
  }
  
  .socialContainer {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: rgb(44, 44, 44);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition-duration: 0.3s;
  }
  
  .containerOne:hover {
    background-color: #d62976;
  }
  
  .containerTwo:hover {
    background-color: #00acee;
  }
  
  .containerThree:hover {
    background-color: #0072b1;
  }
  
  .containerFour:hover {
    background-color: #128C7E;
  }
  
  .socialContainer:active {
    transform: scale(0.9);
    transition-duration: 0.3s;
  }
  
  .socialSvg {
    width: 17px;
  }
  
  .socialSvg path {
    fill: rgb(255, 255, 255);
  }
  
  .socialContainer:hover .socialSvg {
    animation: slide-in-top 0.3s both;
  }
  
  /* Media query para pantallas pequeñas */
  @media (max-width: 768px) {
    footer {
      flex-direction: column; /* Apila los elementos verticalmente en pantallas pequeñas */
      padding: 20px; /* Reduce el padding en pantallas pequeñas */
    }
  
    footer .container {
      flex-direction: column; /* Apila las columnas verticalmente en pantallas pequeñas */
    }
  
    .footer-left,
    .footer-middle,
    .footer-right {
      text-align: center; /* Centra el texto en pantallas pequeñas */
      padding: 10px 0; /* Ajusta el padding para pantallas pequeñas */
    }
  
    .social-icons {
      flex-direction: row; /* Alinea los íconos en una fila en pantallas pequeñas */
      gap: 15px; /* Ajusta el espacio entre los íconos */
    }
  
    .socialContainer {
      width: 40px; /* Tamaño reducido para pantallas pequeñas */
      height: 40px; /* Tamaño reducido para pantallas pequeñas */
    }
  
    .socialSvg {
      width: 15px; /* Tamaño reducido para pantallas pequeñas */
    }
  }
  
  /* Animación para íconos sociales (opcional) */
  @keyframes slide-in-top {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  