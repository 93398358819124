.sponsor {
    display: flex;
    justify-content: center; /* Centra los logos horizontalmente */
    flex-wrap: wrap; /* Permite el ajuste de los logos si hay espacio limitado */
    gap: 10px; /* Espaciado entre los logos */
    margin: 20px 0; /* Margen superior e inferior opcional */
    background-color: #ffffff;
}

.sponsor-logo {
    width: 100px; /* Ancho del logo */
    height: auto; /* Altura automática para mantener la proporción */
    object-fit: contain; /* Mantiene la proporción sin deformar */
    opacity: 0.5; /* Color gris mediante opacidad */
    transition: opacity 0.3s ease; /* Transición suave para cambios de opacidad */
}

.sponsor-logo:hover {
    opacity: 1; /* Aumenta la opacidad al pasar el ratón sobre el logo */
}

/* Responsivo: Ajustes para dispositivos móviles y pantallas pequeñas */
@media (max-width: 768px) {
    .sponsor-logo {
        width: 80px; /* Reduce el ancho del logo en pantallas más pequeñas */
    }
}

/* Responsivo: Ajustes para pantallas aún más pequeñas */
@media (max-width: 480px) {
    .sponsor-logo {
        width: 60px; /* Reduce aún más el ancho del logo en pantallas muy pequeñas */
    }
}
