/* Estilos generales */

.history-section {
    margin-bottom: 20px;
}

.history-title {
    font-size: 2rem;
    margin-bottom: 10px;
}

.history-subtitle {
    font-size: 1.2rem;
    color: black;
    margin-bottom: 20px;
}

.services-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.service-item {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    border: 1px solid #5c2434; /* Color del borde actualizado */
    border-width: 2px;
}

.service-item h3 {
    font-size: 1.5rem;
    color: #5c2434;
}

.service-item p {
    font-size: 1rem;
    color: #333;
}
