.Btn {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    position: relative;
    /* overflow: hidden; */
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .svgContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    backdrop-filter: blur(0px);
    letter-spacing: 0.8px;
    border-radius: 10px;
    transition: all 0.3s;
    border: 1px solid rgba(156, 156, 156, 0.466);
  }
  
  .BG {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #075e54;
    z-index: -1;
    border-radius: 10px;
    pointer-events: none;
    transition: all 0.3s;
  }
  
  .Btn:hover .BG {
    transform: rotate(35deg);
    transform-origin: bottom;
  }
  
  .Btn:hover .svgContainer {
    background-color: rgba(156, 156, 156, 0.466);
    backdrop-filter: blur(4px);
  }
  
  
  .whatsapp {
    position: fixed; /* Fija el botón en la misma posición en la ventana de visualización */
    bottom: 20px; /* Distancia desde la parte inferior de la ventana */
    right: 20px; /* Distancia desde el lado derecho de la ventana */
    z-index: 999; /* Asegura que el botón esté por encima de otros elementos */
    display: flex;
    align-items: center; /* Centra el contenido verticalmente si es necesario */
    justify-content: center; /* Centra el contenido horizontalmente si es necesario */
}