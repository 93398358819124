.policy-content {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    border: 1px solid #5c2434;
    margin-top: 20px;
}

.policy-subtitle {
    font-size: 1.25rem;
    color: #5c2434;
    margin-bottom: 10px;
}

.policy-content p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 20px;
    line-height: 1.5;
}
