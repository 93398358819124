/* Menú de navegación */
/* Contenedor principal del encabezado */
header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #5c2434; /* Ajusta el color de fondo según sea necesario */
}

.navbar {   
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: center; /* Centra el menú en pantallas grandes */
    width: 100%;
    background-color: #5c2434 !important;
}

/* Contenedor del logo */
.logo-container {
    flex: 1;
}

/* Contenedor del menú de navegación */
.nav-container {
    flex: 2;
    display: flex;
    justify-content: center; /* Centra el menú en pantallas grandes */
    background-color: #5c2434 !important;
}

/* Estilo para el menú desplegable completo */
.dropdown-menu {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
}

/* Estilo para los elementos del menú desplegable */
.dropdown-item {
    color: black;
    font-size: 0.9rem;
}

/* Estilo para el elemento del menú desplegable al pasar el cursor */
.dropdown-item:hover {
    background-color: #5c2434;
    color: black;
}

/* Estilo para los submenús */
.dropdown-submenu .dropdown-menu {
    background-color: #5c2434;
    border-left: 1px solid #ddd;
    border-radius: 0.25rem;
}

/* Flecha del dropdown que indica sub-opciones */
.dropdown-submenu .dropdown-toggle::after {
    font-size: 0.8rem;
    color: rgb(0, 0, 0);
    margin-left: 0.5rem;
}

/* Mostrar submenús */
.dropdown-submenu:hover .dropdown-menu {
    display: block;
    position: absolute;
    left: 100%;
    top: 0;
    margin-top: 0;
}

/* Estilo general para el botón del menú desplegable */
.navbar-toggler {
    background-color: #5c2434;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    margin-left: auto; /* Alinea a la derecha en pantallas grandes */
}

/* Ajusta el color de la flecha en el botón del menú */
.navbar-toggler-icon {
    color: black;
}

/* Ensure submenus display correctly */
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .dropdown-menu {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
}

/* Color del texto de los enlaces de navegación a negro */
.navbar-nav .nav-link {
    color: rgb(255, 255, 255);
    position: relative;
}

/* Efecto de línea roja al pasar el cursor */
.navbar-nav .nav-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #ffffff;
    transition: width 0.3s;
    position: absolute;
    bottom: -2px;
    left: 0;
}

.navbar-nav .nav-link:hover::after {
    width: 100%;
}



.navbar-nav .nav-link:hover {
    color: rgb(255, 255, 255);
}

.navbar-nav .dropdown-menu .dropdown-item {
    color: rgb(255, 255, 255);
}

/* Aumenta el espaciado entre los elementos de navegación */
.navbar-nav .nav-item {
    margin-left: 20px;
}

.navbar-nav .nav-item:last-child {
    margin-right: 20px;
}

/* Estilos específicos para dispositivos móviles */
@media (max-width: 768px) {
    .navbar {
        justify-content: space-between; /* Distribuye los elementos correctamente en dispositivos móviles */
    }

    .logo-container {
        flex: 0; /* Ajusta el contenedor del logo para dispositivos móviles */
    }

    .nav-container {
        flex: 0; /* Ajusta el contenedor del menú para dispositivos móviles */
        width: 100%; /* Asegura que el menú ocupe todo el ancho */
        display: block; /* Muestra el contenedor del menú como bloque */
    }

    /* Asegura que el botón del menú esté visible y alineado correctamente */
    .navbar-toggler {
        position: fixed;
        top: 10px;
        right: 20px;
        z-index: 999; /* Asegura que el botón esté sobre otros elementos */
    }

    /* Asegura que los enlaces del menú sean blancos y grandes */
    .nav-link {
        color: white !important;
        font-size: 1.2rem; /* Aumenta el tamaño de la fuente */
        
    }

    /* Estilo del menú desplegable */
    .navbar-collapse {
        display: none; /* Oculta el menú por defecto */
        position: fixed; /* Posición fija para que el menú se quede en la pantalla */
        top: 0; /* Alinea el menú al borde superior */
        left: -250px; /* Oculta el menú a la izquierda fuera de la pantalla */
        width: 250px; /* Ancho del menú */
        height: 100%; /* Altura completa de la pantalla */
        background-color: #5c2434; /* Color de fondo del menú */
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5); /* Opcional: añade una sombra para el efecto de profundidad */
        transition: left 0.3s ease; /* Transición para el efecto de apertura */
        z-index: 999; /* Asegura que el menú esté encima de otros elementos */
    }

    /* Estilo cuando el menú está visible */
    .navbar-collapse.show {
        display: block;
        left: 0; /* Muestra el menú moviéndolo a la izquierda */
    }

    .navbar-nav {
        flex-direction: column; /* Alinea los elementos del menú verticalmente */
        margin-top: 10px; /* Ajusta el margen superior del menú */
    }

    .navbar-nav .nav-item {
        margin-left: 0; /* Elimina el margen izquierdo en vista móvil */
        margin-bottom: 0; /* Asegura que no haya margen inferior en los elementos del menú */
    }

    .navbar-nav .nav-item:last-child {
        margin-bottom: 0; /* Elimina el margen inferior en el último ítem */
    }
}

